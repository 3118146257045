import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../axios/API";
import { getRFQ, payment, sendReplayMail } from "../../axios/ServerRequest";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { json } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";

function Quotation(props) {
  const [page, setPage] = useState(1);
  const [end, setEnd] = useState(1);
  const [quotations, setQuotations] = useState([]);
  const [quote, setQuote] = useState([]);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState("");
  const [details, setDetails] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleModalClose = () => setModalShow(false);
  const handleMOdalShow = () => setModalShow(true);

  useEffect(() => {
    fetchRFQ();
  }, []);

  const fetchRFQ = async () => {
    await getRFQ(page)
      .then((res) => {
        if (res.status === 200) {
          setQuotations(res.data.quotations.data);

          if (
            res.data.quotations.current_page !== res.data.quotations.last_page
          ) {
            setPage(page + 1);
          } else {
            setEnd(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadMore = async () => {
    await getRFQ(page)
      .then((res) => {
        if (res.status === 200) {
          setQuotations([...quotations, ...res.data.quotations.data]);

          if (
            res.data.quotations.current_page !== res.data.quotations.last_page
          ) {
            setPage(page + 1);
          } else {
            setEnd(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Quotation
    </Tooltip>
  );

  const renderTooltip1 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Send Email
    </Tooltip>
  );

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: quote.product_name,
            amount: {
              currency_code: "USD",
              value: quote.total_amount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setDetails(details);
      setSuccess(true);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    setShow(false);
    console.log("orderID=>", orderID);
    console.log("Details==>", details);
    console.log(success);
    if (success) {
      savePayment();
    }
  }, [success]);

  const savePayment = async () => {
    const dataString = JSON.stringify(details);
    const data = {
      name: quote.user_name,
      provider: "paypal",
      amount: quote.total_amount,
      payment_id: orderID,
      quote_id: quote.id,
      payment_done: 1,
      response: dataString,
      status: "success",
    };

    await payment(data)
      .then((res) => {
        console.log(res.data);
        setSuccess(false);
        setOrderID("");
        setDetails(null);
        fetchRFQ();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendMail = async () => {
    setErrorMessage("");
    if (message.length === 0) {
      setErrorMessage("Enter your Message.");
    } else {
      setLoading(true);
      const data = {
        id: quote.id,
        message: message,
      };

      await sendReplayMail(data)
        .then((res) => {
          console.log(res.data);
          handleModalClose();

          toast.success("Email Sent Successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          handleModalClose();
          toast.error("Server Error. Please try after sometime.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table table-striped">
        <thead>
          <tr className="quote-header">
            <th scope="col">quotation ID</th>
            <th scope="col">Product</th>
            <th scope="col">Quantity</th>
            <th scope="col">Category</th>
            <th scope="col">Price</th>
            <th scope="col">Attachment</th>
            <th scope="col">Status</th>
            <th scope="col">Option</th>
          </tr>
        </thead>
        <tbody>
          {quotations.map((item, index) => {
            return (
              <tr className="quote">
                <td>{item.quotation_id}</td>
                <td>{item.product_name}</td>
                <td>
                  {item.quantity} {item.unit}
                </td>
                <td>{item.category}</td>
                <td>{item.total_amount ? "$" + item.total_amount : ""}</td>
                <td className="d-flex flex-row flex-wrap">
                  {item.attachment &&
                    item.attachment.length > 0 &&
                    item.attachment.split(",").map((item1, key) => {
                      return (
                        <div className="me-2">
                          <a href={BASE_URL + item1} target="_blank">
                            <i
                              className="fa fa-file-pdf-o"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      );
                    })}
                </td>
                <td>{item.status}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <i
                      className="fa fa-eye fa-1x cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        setQuote(item);
                        handleShow();
                      }}
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip1}
                  >
                    <i
                      className="fa fa-envelope fa-1x cursor-pointer ms-2"
                      aria-hidden="true"
                      onClick={() => {
                        setQuote(item);
                        handleMOdalShow();
                      }}
                    ></i>
                  </OverlayTrigger>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="text-center">
        {!end && (
          <button className="btn btn-sm btn-info" onClick={loadMore}>
            Load More
          </button>
        )}
      </div>

      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{quote?.quotation_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <strong>Delivered To :</strong>
            </div>
            <div>{quote.user_name}</div>
            <div>{quote.email}</div>
            <div>{quote.phone}</div>
            <div>{quote.address}</div>
            <hr />
            <div className="row">
              <div className="col-md-8 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Product Name :
                </label>
                <br />
                {quote.product_name}
              </div>
              <div className="col-md-4 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Product Quantity :
                </label>
                <br />
                {quote.quantity} {quote.unit}
              </div>
              <div className="col-md-12 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Product Description :
                </label>
                <br />
                {quote.description}
              </div>

              <div className="col-md-3 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Product Category :
                </label>
                <br />
                {quote.category}
              </div>

              <div className="col-md-3 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Total Price :
                </label>
                <br />
                {quote.price ? "$" + quote.price : ""}
              </div>
              <div className="col-md-3 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Discount :
                </label>
                <br />
                {quote.discount ? quote.discount + "%" : ""}
              </div>
              <div className="col-md-3 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Final Amount :
                </label>
                <br />
                {quote.total_amount ? "$" + quote.total_amount : ""}
              </div>
              <div>
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Payment :
                </label>
                <br />
                {quote.payment ? (
                  <b className="text-success">Success</b>
                ) : (
                  <b className="text-success">Pending</b>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <label style={{ fontSize: 14, fontWeight: 600 }}>
                  Quotation Attachments :
                </label>
                <br />
                <div className="d-flex flex-row flex-wrap">
                  {quote.attachment &&
                    quote.attachment.length > 0 &&
                    quote.attachment.split(",").map((item1, key) => {
                      return (
                        <div className="me-3 mt-3">
                          <a href={BASE_URL + item1} target="_blank">
                            <i
                              className="fa fa-file-pdf-o fa-2x"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" onClick={handleClose}>
            Pay
          </Button> */}
          {quote.total_amount &&
            quote.total_amount > 20 &&
            quote.payment == null && (
              <PayPalButtons
                style={{ layout: "horizontal" }}
                createOrder={createOrder}
                onApprove={onApprove}
              />
            )}

          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" centered show={modalShow} onHide={handleModalClose}>
        <Modal.Body>
          <div class="form-group mb-3">
            <label for="exampleInputEmail1">Quotation</label>
            <input
              type="text"
              class="form-control"
              disabled
              value={quote.quotation_id}
            />
          </div>
          <div class="form-group mb-3">
            <label for="exampleFormControlTextarea1">Enter Your Message</label>
            <textarea
              style={{ minHeight: 150 }}
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <div style={{ color: "red", fontSize: 12 }}>{errorMessage}</div>

            <button type="button" class="btn btn-primary" onClick={sendMail}>
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default Quotation;
