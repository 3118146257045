import axios from "axios";
import LocalStorage from "../utils/LocalStorage";
const URL = "https://admin.globalfactory365.com";
//  const URL = "http://192.168.1.102:8000";
// const URL = "https://admingf.frontendsourcecode.com";

export const BASE_URL = URL;

const API = async (config) => {
  const token = await LocalStorage.getApiKey();
  if (token) {
    config.headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
  }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: "net work error",
          status: 500,
        };
      }
      if (error.response.status === 401) {
        console.log("Unauthorised");
        LocalStorage.logout();
        // window.location.reload();
      }
      return Promise.reject(error);
    }
  );
  config.baseURL = URL;
  return axios(config);
};
export default API;
